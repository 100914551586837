/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #fa5f57;
  --secondary: #390020;
  --tertiary: null;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/CTA_skugga_moerkroed_2_1_5c617c3c63.png);
  --shadow-ball: url(/images/content/erpeskollen_boll_20f3c8367b_8902a8c942.png);
  --shadow-line: url(/images/content/menskollen_element_kant_be5b51e445.png);
}